










import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(_, { root }) {
    const language = ref(root.$store.getters['language/getLanguage'])

    const items = computed(() => [
      { value: 'pl', text: root.$tc('languages.pl') },
      { value: 'en', text: root.$tc('languages.en') },
    ])

    watch(() => language.value, lang => root.$store.dispatch('language/setLanguage', lang))
    watch(() => root.$store.getters['language/getLanguage'], lang => language.value = lang)

    onMounted(() => root.$store.dispatch('language/updateLanguage'))

    const getFlagCode = (value: string) => {
      switch (value) {
        case 'en': return 'gb'
        default: case 'pl': return 'pl'
      }
    }

    return { language, items, getFlagCode }
  }
})
